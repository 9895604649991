<template>
  <div class="p-4">
    <div class="flex justify-end">
      <button
        class=":hover:text-gray-700 text-2xl text-gray-500"
        @click="closeModal"
        aria-label="Close"
      >
        <font-awesome-icon :icon="['fal', 'xmark']" />
      </button>
    </div>
    <div
      class="relative flex w-full items-center justify-center overflow-hidden"
    >
      <transition
        enter-active-class="miniFadeInTop"
        leave-active-class="miniFadeOutBottom"
        mode="out-in"
      >
        <image-getter
          classes="object-cover  h-full"
          :url="getImageUrl(data.image.food, 'ar-1_1,w-800')"
          default="/images/product/awaitingimage.webp"
          :key="data.sku"
        />
      </transition>
      <transition
        enter-active-class="miniFadeInTop"
        leave-active-class="miniFadeOutBottom"
        mode="out-in"
      >
        <div
          :key="`product-${data.sku}`"
          class="absolute bottom-0 right-0 -mb-2 h-40 w-40 overflow-hidden rounded-full p-4 sm:h-32 sm:w-32 md:h-40 md:w-40 xl:h-40 xl:w-40"
        >
          <image-getter
            classes="object-cover  h-full"
            :url="getImageUrl(data.image.packaging, 'ar-1_1,w-400')"
            default="/images/product/awaitingimage.webp"
            :key="data.sku"
          />
        </div>
      </transition>
    </div>
    <div class="flex flex-col">
      <div
        class="mb-0.5 line-clamp-2 flex h-10 w-full items-end justify-center space-x-1 whitespace-normal text-center font-medium"
      >
        <span>{{ data.shortname || data.name }}</span>
        <span class="" v-if="data.isKitten">(Kitten)</span>
      </div>
      <div class="line-clamp-2 px-2 text-center text-sm text-gray-600">
        {{ data.foodcomplete ? 'Complete Food' : 'Complementary Food' }}
        &middot;
        {{ data.foodtype === 'dry' ? data.increments : data.size }}g
      </div>
      <div class="space-y-4 pt-6 text-sm">
        <div class="text-sm text-gray-600">{{ data.description }}</div>
        <div>
          <div class="font-medium">Composition</div>
          <div class="text-sm text-gray-600">{{ data.composition }}</div>
        </div>
        <div>
          <div class="font-medium">Analytical Constituents</div>
          <div class="text-sm text-gray-600">{{ data.constituents }}</div>
        </div>
        <div>
          <div class="font-medium">Nutritional Additives</div>
          <div class="text-sm text-gray-600">{{ data.additives }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageGetter from '../../ImageGetter.vue';
import utils from '@/utils';

export default {
  components: { ImageGetter },
  name: 'ProductInfo',
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  methods: {
    closeModal: function () {
      this.$parent.$emit('make-close');
    },
    getImageUrl(url, config) {
      return utils.transformImage(url, config);
    }
  }
};
</script>

<style></style>
